import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-simulator-health',
  templateUrl: './simulator-health.component.html',
  styleUrls: ['./simulator-health.component.less']
})
export class SimulatorHealthComponent implements OnInit {

  firstCritical: string;
  lastCritical: string;
  criticalLog: string;
  log: string;

  showLog: boolean;
  showCriticalLog: boolean;

  static cacheKey(): string {
    const d = new Date();
    return d.getFullYear() + '' + d.getMonth() + '' + d.getDate() + '' + d.getHours() + '' + d.getMinutes();
  }

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.setDataPoints();
    setInterval(() => {
      this.setDataPoints();
    }, 60000);
  }

  setDataPoints(): void {
    this.httpClient.get(
      '/assets/logsnapshot.json?r=' + SimulatorHealthComponent.cacheKey()
    ).toPromise().then((r: object[]) => {
      Object.assign(this, r);
    });
  }

}
