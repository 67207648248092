import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-simulation-status',
  templateUrl: './simulation-status.component.html',
  styleUrls: ['./simulation-status.component.less']
})
export class SimulationStatusComponent implements OnInit {

  @Input()
  simulationStatusType: SimulationStatusType;

  public simulationStatus: SimulationStatus;

  showLog = false;

  constructor(private httpClient: HttpClient) {
  }

  static cacheKey(): string {
    const d = new Date();
    return d.getFullYear() + '' + d.getMonth() + '' + d.getDate() + '' + d.getHours() + '' + d.getMinutes();
  }

  ngOnInit() {
    this.setDataPoints();
    setInterval(() => {
      this.setDataPoints();
    }, 60000);
  }

  setDataPoints(): void {
    this.httpClient.get(
      '/assets/simulationstatuses.json?r=' + SimulationStatusComponent.cacheKey()
    ).toPromise().then((statuses: SimulationStatus[]) => {
      console.log('statuses', statuses);
      for (const status of statuses) {
        if (status.type === (this.simulationStatusType as SimulationStatusType)) {
          this.simulationStatus = new SimulationStatus(status);
        }
      }
    });
  }

}

export enum SimulationStatusType {
  TwitterFlexSimulation,
  NewsFlexSimulation,
  NumericalFlexSimulation,
  CombinedSimulation
}

export class SimulationStatus {
  type: SimulationStatusType;
  name: string;
  lastStarted: Date;
  lastCompleted: Date;
  latestFailureLog: string;
  totalPending = 0;
  totalProcessed = 0;
  startingPrice: number;
  finalPrice: number;
  get percentageDone() {
    return Math.round(((this.totalProcessed) / (this.totalPending + 1)) * 100);
  }
  constructor(other: object) {
    Object.assign(this, other);
  }
}
