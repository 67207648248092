import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { CombinedLineChartComponent } from './combined-line-chart/combined-line-chart.component';
import { HttpClientModule } from '@angular/common/http';
import { SimulationStatusComponent } from './simulation-status/simulation-status.component';
import { SimulationResultComponent } from './simulation-result/simulation-result.component';
import {SimulatorHealthComponent} from './simulator-health/simulator-health.component';

@NgModule({
  declarations: [
    AppComponent,
    CombinedLineChartComponent,
    SimulationStatusComponent,
    SimulationResultComponent,
    SimulatorHealthComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
