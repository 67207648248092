import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SimulationStatus, SimulationStatusType} from '../simulation-status/simulation-status.component';

@Component({
  selector: 'app-simulation-result',
  templateUrl: './simulation-result.component.html',
  styleUrls: ['./simulation-result.component.less']
})
export class SimulationResultComponent implements OnInit {

  @Input()
  payload: string;

  @Input()
  title: string;

  show: boolean;

  @Input()
  simulationStatusType: SimulationStatusType;

  public simulationStatus: SimulationStatus;

  public simulationResult: {
    differential: number,
    startDate: string,
    endDate: string,
    tradeCount: number
  };

  constructor(private httpClient: HttpClient) {
  }

  static cacheKey(): string {
    const d = new Date();
    return d.getFullYear() + '' + d.getMonth() + '' + d.getDate() + '' + d.getHours() + '' + d.getMinutes();
  }

  ngOnInit() {
    this.setSimulationResult();
    this.setSimulationStatus();
    setInterval(() => {
      this.setSimulationResult();
      this.setSimulationStatus();
    }, 60000);
  }

  setSimulationResult(): void {
    this.httpClient.get(
      '/assets/' + this.payload + '.json?r=' + SimulationResultComponent.cacheKey()
    ).toPromise().then((r: any) => {
      this.simulationResult = r;
    });
  }

  setSimulationStatus(): void {
    this.httpClient.get(
      '/assets/simulationstatuses.json?r=' + SimulationResultComponent.cacheKey()
    ).toPromise().then((statuses: SimulationStatus[]) => {
      for (const status of statuses) {
        if (status.type === (this.simulationStatusType as SimulationStatusType)) {
          this.simulationStatus = new SimulationStatus(status);
        }
      }
    });
  }

  niceDate(input: string): string {
    const d = new Date(input);
    return d.toDateString();
  }

  niceTime(input: string): string {
    const d = new Date(input);
    return ((d.getHours() >= 10) ? d.getHours() : '0' + d.getHours()) + ':' +
      ((d.getMinutes() >= 10) ? d.getMinutes() : '0' + d.getMinutes());
  }

  differenceInDays(date1, date2): number {
    return Math.floor((new Date(date2).getTime() - new Date(date1).getTime()) / 86400000);
  }

  relativeDifferential() {
    try {
      const relativePriceChange = this.simulationStatus.finalPrice / this.simulationStatus.startingPrice;
      if (!Number.isNaN(relativePriceChange)) {
        return relativePriceChange * this.simulationResult.differential;
      }
    } catch (e) {
      console.log('unable to determine relative differential', e);
    }
    return null;
  }

}
