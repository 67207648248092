import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-combined-line-chart',
  templateUrl: './combined-line-chart.component.html',
  styleUrls: ['./combined-line-chart.component.less']
})
export class CombinedLineChartComponent implements OnInit {

  @Input()
  graphIndex: number;

  @Input()
  graphTitle: string;

  @Input()
  description: string;

  @Input()
  depth: number;

  @Input()
  chunkSize = 10;

  @Input()
  zeroBased = false;

  @Input()
  payload: string;

  @ViewChild(BaseChartDirective, { static: false })
  baseChart: BaseChartDirective;

  lastEntry: {};

  public simulationResult: {
    differential: number,
    startDate: string,
    endDate: string,
    tradeCount: number,
    sellThreshold: number,
    buyThreshold: number,
  };

  get buyCorrelation() {
    return !this.zeroBased ? this.lastEntry[this.graphIndex] * 100 : 50 + (this.lastEntry[this.graphIndex] * 100);
  }

  get sellCorrelation() {
    return !this.zeroBased ? (1 - this.lastEntry[this.graphIndex]) * 100 : 50 + (-this.lastEntry[this.graphIndex] * 100);
  }

  get recommendation() {
    const buyThreshold = (this.simulationResult) ? this.simulationResult.buyThreshold : 0.5;
    const sellThreshold = (this.simulationResult) ? this.simulationResult.sellThreshold : 0.5;
    // console.log('------------');
    // console.log('this.buyCorrelation', this.buyCorrelation);
    // console.log('buyThreshold', buyThreshold);
    // console.log('this.sellCorrelation', this.sellCorrelation);
    // console.log('sellThreshold', sellThreshold);
    // console.log('------------');
    return (this.buyCorrelation / 100) > buyThreshold ? 'buy' : (this.sellCorrelation / 100) > sellThreshold ? 'sell' : 'hold';
  }

  get confidence() {
    return Math.abs(this.buyCorrelation - this.sellCorrelation);
  }

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'BTC/USD Price',
      yAxisID: 'price',
      pointRadius: 0
    },
    {
      data: [],
      label: 'Market Sentiment',
      yAxisID: 'probability',
      pointRadius: 0
    },
    {
      data: [],
      label: 'Buy Threshold',
      yAxisID: 'probability',
      pointRadius: 0
    },
    {
      data: [],
      label: 'Sell Threshold',
      yAxisID: 'probability',
      pointRadius: 0
    },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: 'probability',
          position: 'right',
          display: false,
        },
        {
          id: 'price',
          position: 'left',
          display: false,
        },
      ],
      xAxes: [
        {
          id: 'date',
          position: 'bottom',
          display: false
        }
      ]
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgb(151,254,160)',
      borderWidth: 1,
      backgroundColor: 'rgba(151,254,160,0.00)',
    },
    {
      borderColor: 'rgb(255,200,0)',
      borderWidth: 0,
      backgroundColor: 'rgba(255,200,0,0.00)',
    },
    {
      borderColor: 'rgb(153,254,146)',
      borderWidth: 0,
      backgroundColor: 'rgba(153,254,146,0.00)',
    },
    {
      borderColor: 'rgb(255,113,113)',
      borderWidth: 0,
      backgroundColor: 'rgba(255,113,113,0.00)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor(private httpClient: HttpClient) {
  }

  static cacheKey(): string {
    const d = new Date();
    return d.getFullYear() + '' + d.getMonth() + '' + d.getDate() + '' + d.getHours() + '' + d.getMinutes();
  }

  niceDate(input: string) {
    const d = new Date(input);
    return d.toDateString() + ' ' +
      ((d.getHours() >= 10) ? d.getHours() : '0' + d.getHours()) + ':' +
      ((d.getMinutes() >= 10) ? d.getMinutes() : '0' + d.getMinutes());
  }

  async ngOnInit() {
    await this.setSimulationResult();
    await this.setDataPoints();
    setInterval(async () => {
      await this.setSimulationResult();
      await this.setDataPoints();
    }, 60000);
  }

  async setSimulationResult() {
    return this.httpClient.get(
      '/assets/' + this.payload + '.json?r=' + CombinedLineChartComponent.cacheKey()
    ).toPromise().then((r: any) => {
      this.simulationResult = r;
    });
  }

  async setDataPoints() {
    return this.httpClient.get(
      '/assets/datapoints.json?r=' + CombinedLineChartComponent.cacheKey()
    ).toPromise().then((r: object[]) => {
      this.lineChartLabels = [];
      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      const start = (r.length > this.depth) ? r.length - this.depth : 0;
      for (let i = start; i < r.length; i += this.chunkSize) {
        const v = r[i];
        if (v !== undefined) {
          this.lineChartLabels.push(this.niceDate(v[0]));
          this.lineChartData[0].data.push(v[1]);
          this.lineChartData[1].data.push(this.zeroBased ? v[this.graphIndex] + 0.5 : v[this.graphIndex]);
          this.lineChartData[2].data.push(this.simulationResult.buyThreshold);
          this.lineChartData[3].data.push(1 - this.simulationResult.sellThreshold);
        }
      }
      this.lastEntry = r[r.length - 1];
      this.baseChart.ngOnChanges({});
    });
  }

}
